var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"cw-business-loan-signees__owners"},[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('h3',{staticClass:"text-subtitle-1 font-weight-bold mb-1"},[_c('translate',[_vm._v("Adding Beneficiaries")])],1),_c('p',{staticClass:"ma-0"},[_c('translate',[_vm._v(" Pursuant to the Money Laundering Act, the creditor must identify the beneficiaries of the decision-making company behind the client. Under the Money Laundering Act, the beneficiaries of a business are those who either own the business or otherwise control the business. ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mx-0",attrs:{"id":"open-dialog--owner-info","color":"primary lighten-1","text":"","small":""},on:{"click":function($event){_vm.$bus.$emit('OPEN_CONFIRMATION_DIALOG', {
            component: 'DialogOwnerInfo',
            folder: 'fi',
          });
          _vm.$eventLogger.clickEvent($event);}}},[_c('translate',[_vm._v("More information")])],1)],1)],1),_c('v-col',{staticClass:"\n      cw-border-container\n      cw-border-container--secondary\n      cw-border-container--md-center\n    ",class:{ 'error--text': _vm.error },attrs:{"id":"table__owners","cols":"12","md":"7"}},[_c('v-card-actions',{staticClass:"px-0"},[_c('h3',{staticClass:"text-subtitle-1 font-weight-bold mb-1"},[_c('translate',[_vm._v(" The beneficial owners ")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"id":"open-dialog--owner","dark":!_vm.$wait.waiting('owner__add'),"disabled":_vm.$wait.waiting('owner__add'),"loading":_vm.$wait.waiting('owner__add'),"color":"primary lighten-1","outlined":"","small":""},on:{"click":function($event){_vm.onDialogOpen();
          _vm.$eventLogger.clickEvent($event);}}},[_c('translate',[_vm._v("Add")])],1)],1),_c('v-alert',{staticClass:"ma-0",attrs:{"type":_vm.alert.type,"value":_vm.alert.show,"transition":"fade-transition","dense":"","tile":""}},[_vm._v(" "+_vm._s(_vm.alert.text)+" ")]),_c('div',{staticClass:"cw-application__table"},[_c('table',[_c('tbody',[(_vm.form.owners.length)?[_vm._l((_vm.owners),function(owner,index){return _c('tr',{key:owner.ssn,staticClass:"cw-business-loan-signees__table-row--owner",attrs:{"id":("cw-business-loan-signees__table-row--owner-" + (index + 1))}},[_c('td',{class:{ 'error--text': !owner.valid && owner.field === 'name' }},[_vm._v(" "+_vm._s(owner.name)+" ("+_vm._s(owner.percentage)+"%) ")]),_c('td',{staticClass:"text-right font-weight-bold",class:{ 'error--text': !owner.valid && owner.field === 'ssn' }},[_vm._v(" "+_vm._s(owner.ssn)+" ")]),_c('td',{attrs:{"width":"24"}},[_c('v-btn',{attrs:{"id":("remove-owner__" + (owner.ssn)),"color":"grey darken-1","text":"","icon":""},on:{"click":function($event){_vm.onRemoveItem(owner, 'owner');
                    _vm.$eventLogger.clickEvent($event);}}},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" delete ")])],1)],1)])}),_c('tr',{staticClass:"table-footer"},[_c('td',{attrs:{"colspan":"3"}},[_c('translate',[_vm._v("In total")]),_vm._v(": "),_c('span',{staticClass:"font-weight-bold",class:{
                    'error--text': _vm.totalPercentage > 100,
                    'success--text': _vm.totalPercentage < 100.01 && _vm.totalPercentage > 99.99,
                  }},[_vm._v(_vm._s(_vm.totalPercentage)+"%")])],1)])]:[_c('tr',{staticClass:"cw-business-loan-signees__table-row--no-data-owner"},[_c('td',[_c('translate',[_vm._v("No beneficiaries added.")])],1)])]],2)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }