<template>
  <v-row class="cw-business-loan-signees__owners">
    <v-col
      cols="12"
      md="5"
    >
      <h3 class="text-subtitle-1 font-weight-bold mb-1">
        <translate>Adding Beneficiaries</translate>
      </h3>
      <p class="ma-0">
        <translate>
          Pursuant to the Money Laundering Act, the creditor must identify the beneficiaries of the
          decision-making company behind the client. Under the Money Laundering Act, the
          beneficiaries of a business are those who either own the business or otherwise control
          the business.
        </translate>
      </p>

      <v-card-actions>
        <v-spacer/>
        <v-btn
          id="open-dialog--owner-info"
          color="primary lighten-1"
          class="mx-0"
          text
          small
          @click="
            $bus.$emit('OPEN_CONFIRMATION_DIALOG', {
              component: 'DialogOwnerInfo',
              folder: 'fi',
            });
            $eventLogger.clickEvent($event);
          "
        >
          <translate>More information</translate>
        </v-btn>
      </v-card-actions>
    </v-col>

    <v-col
      id="table__owners"
      :class="{ 'error--text': error }"
      class="
        cw-border-container
        cw-border-container--secondary
        cw-border-container--md-center
      "
      cols="12"
      md="7"
    >
      <v-card-actions class="px-0">
        <h3 class="text-subtitle-1 font-weight-bold mb-1">
          <translate>
            The beneficial owners
          </translate>
        </h3>
        <v-spacer/>
        <v-btn
          id="open-dialog--owner"
          :dark="!$wait.waiting('owner__add')"
          :disabled="$wait.waiting('owner__add')"
          :loading="$wait.waiting('owner__add')"
          color="primary lighten-1"
          outlined
          small
          @click="
            onDialogOpen();
            $eventLogger.clickEvent($event);
          "
        >
          <translate>Add</translate>
        </v-btn>
      </v-card-actions>

      <v-alert
        :type="alert.type"
        :value="alert.show"
        transition="fade-transition"
        class="ma-0"
        dense
        tile
      >
        {{ alert.text }}
      </v-alert>

      <div class="cw-application__table">
        <table>
          <tbody>
            <template v-if="form.owners.length">
              <tr
                v-for="(owner, index) in owners"
                :id="`cw-business-loan-signees__table-row--owner-${index + 1}`"
                :key="owner.ssn"
                class="cw-business-loan-signees__table-row--owner"
              >
                <td
                  :class="{ 'error--text': !owner.valid && owner.field === 'name' }"
                >
                  {{ owner.name }} ({{ owner.percentage }}%)
                </td>
                <td
                  :class="{ 'error--text': !owner.valid && owner.field === 'ssn' }"
                  class="text-right font-weight-bold"
                >
                  {{ owner.ssn }}
                </td>
                <td width="24">
                  <v-btn
                    :id="`remove-owner__${owner.ssn}`"
                    color="grey darken-1"
                    text
                    icon
                    @click="
                      onRemoveItem(owner, 'owner');
                      $eventLogger.clickEvent($event);
                    "
                  >
                    <v-icon size="18">
                      delete
                    </v-icon>
                  </v-btn>
                </td>
              </tr>

              <tr class="table-footer">
                <td colspan="3">
                  <translate>In total</translate>:
                  <span
                    :class="{
                      'error--text': totalPercentage > 100,
                      'success--text': totalPercentage < 100.01 && totalPercentage > 99.99,
                    }"
                    class="font-weight-bold"
                  >{{ totalPercentage }}%</span>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr class="cw-business-loan-signees__table-row--no-data-owner">
                <td>
                  <translate>No beneficiaries added.</translate>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CwBusinessLoanSigneesOwners',

  props: {
    form: {
      type: Object,
      default: () => ({}),
    },

    validated: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      apiErrors: 'application/apiErrors',
    }),

    alert() {
      const { error, owners } = this;
      const hasInvalidOwnerData = owners.some(owner => owner.valid === false);

      return {
        show: error || hasInvalidOwnerData,
        type: 'error',
        text: hasInvalidOwnerData
          ? this.$gettext('Incorrect owner information')
          : this.$gettext('Company representatives are missing'),
      };
    },

    error() {
      return this.validated && !this.validOwnership;
    },

    owners() {
      if (!this.apiErrors || !this.apiErrors.length) return this.form.owners;

      const apiError = this.apiErrors.find(error => error.startsWith('owners.')) || 'null.-1.null';
      const [, index, field] = apiError.split('.');

      return this.form.owners
        .map((owner, i) => {
          const data = {
            ...owner,
            valid: i !== parseInt(index, 10),
          };

          if (!data.valid) {
            data.field = field;
          }

          return data;
        });
    },

    totalPercentage() {
      return (this.form.owners.reduce((amount, item) => amount + parseFloat(item.percentage), 0))
        .toFixed(2);
    },

    validOwnership() {
      const { form, totalPercentage } = this;

      return totalPercentage <= 100 && form.owners.length > 0;
    },
  },

  watch: {
    validOwnership: {
      handler(valid) {
        this.$bus.$emit('SIGNEES_DIALOG_OWNESHIP_STATUS', valid);
      },
      immediate: true,
    },
  },

  methods: {
    onDialogOpen() {
      const { $bus, form: { owners }, totalPercentage } = this;

      $bus.$emit('SIGNEES_DIALOG_OPEN', {
        type: 'owner',
        data: {
          index: owners.length,
          owners,
          max: 100 - totalPercentage,
        },
      });
    },

    onRemoveItem(item, type) {
      this.$bus.$emit('SIGNEES_ITEM_REMOVE', { item, type });
    },
  },
};
</script>

<style lang="scss">
.cw-business-loan-signees__owners {
  .table-footer td {
    background-color: rgba(0, 0, 0, 0.01);
    box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.1);
    padding: 8px 4px;
  }

  .cw-application__table {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
</style>
